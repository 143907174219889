<template>
	<div style="background-color: #44444400;padding:60px 0px 0 0px;">
		<div class="pageTitle">团队</div>
		<div style="width: 95vw;margin: 0 0% 0 5%;height:calc(100vh - 180px);line-height: 0;">

			<div v-for="team,index in teamList.list" :key="index" class="hangTeam" style="">
				<div style="border-bottom:1px solid #242933;width:95%;margin-bottom: 10px;padding: 2.5%;">
					<el-image :src="$VUE_APP_PIC_API+team.icon" :fit="'cover'"
						style="width: 4vh;height: 4vh;vertical-align: middle;"></el-image>
					<span style="height: 4vh;line-height:4vh;color: #242933;letter-spacing: 10px;margin-left:5px;">
						{{team.name}}
					</span>


				</div>

				<div v-for="user,index in team.users" @click="openPeople(user)"
					style="width: 50px;height: 50px;margin:1.5%;display: inline-block;cursor: pointer;" :key="index">
					<el-image
						:src="$VUE_APP_PIC_API+user.avatar+'?x-oss-process=image/resize,m_fill,w_100,h_100/quality,q_80'"
						:fit="'cover'" style="width: 50px;height: 50px;border-radius: 5px;">
					</el-image>
				</div>
				<!-- <el-image  src="/images/plus.png" :fit="'cover'"
			  style="width: 48px;height: 48px;display: inline-block;margin: 1.5%;
			  border-radius: 5px;border: 1px solid #242933;" ></el-image> -->
				<div v-if="team.stateInvite" @click="invitClick(team)"
					style="width: 48px;height: 48px;display: inline-block;margin: 1.5%;border-radius: 5px;border: 1px solid #009CE3;cursor: pointer;">
					<svg t="1697106072292" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
						p-id="7156">
						<path fill="#DB007F" width="48" height="48"
							d="M512 512V170.666667h42.666667v341.333333h341.333333v42.666667h-341.333333v341.333333h-42.666667v-341.333333H170.666667v-42.666667h341.333333z"
							p-id="7157">

						</path>
					</svg>

				</div>
				<!-- <p class="blockP"><span class="block gray" /></p> -->
			</div>
			<!-- <div v-for="team,index in teamList.list" :key="index" class="hangTeam"
			style="">
			<div style="height: 7vh;width:100px; text-align: center;display: inline-block;" >
				<el-image  :src="$VUE_APP_PIC_API+team.icon" :fit="'cover'" style="width: 4vh;height: 4vh;margin: 1vh;"></el-image>
				
				<div id="" style="font-size: 0.5vh;text-align: center;color: #2A9CF5;">
					{{team.name}}
				</div>
			</div>
			<el-image v-for="user,index in team.users" :src="$VUE_APP_PIC_API+user.avatar+'?x-image-process=image/resize,w_50,h_50/quality,q_80'" :fit="'cover'"
			 style="width: 25px;height: 25px;border-radius: 2.5px;margin-right: 2px;display: inline-block;" :key="index"></el-image>
			 
			 <p class="blockP"><span class="block blue1" /></p>
			 
			 <el-image  src="/images/plus.png" :fit="'cover'"
			  style="width: 23px;height: 23px;display: inline-block;border-radius: 2.5px;border: 1px solid white;margin-right: 20px;" ></el-image>
			 
		</div> -->
		</div>
		<!-- <el-button text @click="dialogTableVisible = true">
		    open a Table nested Dialog
		  </el-button>

  <el-dialog v-model="dialogTableVisible" title="Shipping address">
    <div style="position:absolute;width: 100vw;height: 90vh;top: 10vh;z-index: 1000;">
    	<el-carousel :interval="4000" type="card" height="90vh" indicator-position="outside">
    	    <el-carousel-item v-for="item in 60" :key="item">
    			<div style="background-color: #000000;height: 100vh;">
    				<h3 text="2xl" justify="center">{{ item }}</h3>
    			</div>
    	      
    	    </el-carousel-item>
    	  </el-carousel>
    </div>
  </el-dialog> -->

		<!-- <el-carousel :interval="0" type="card" height="90vh" indicator-position="none">
		        <el-carousel-item v-for="people in peopleList" :key="people.id">
		          <h3 text="2xl" justify="center">{{ people.realName }}</h3>
				  <el-image :src="$VUE_APP_PIC_API+people.avatar+''" :fit="'cover'"
				   style="width: 100%;height: 100%;border-radius: 5px;" >
				   </el-image>
		        </el-carousel-item>
		      </el-carousel> -->
		<div style="position: absolute;top: 0;height: calc(100vh - 0px);width: calc(100vw - 60px);background-color: #000000c8;z-index: 10;
			  padding: 0px 30px 0 30px;" v-show="dialogVisible">

			<el-carousel :ref="'carousel'" :interval="0" arrow="always" style="height: calc(100vh - 0px);" height="100%"
				indicator-position="none">
				<el-carousel-item v-for="people in peopleList" :key="people.id" @Click="closePeople()"
					:name="people.id">
					<!-- <h3 text="2xl" justify="center">{{ people.realName }}</h3> -->
					<div style="margin: 30px 0 0 30px;">
						<div v-if="people.nickName" class="fontDiv">
							<span :data-content='people.nickName' class="fontOutLine"
								style="font-size: 50px;vertical-align: top;">
								{{people.nickName}}
							</span>
							<span :data-content='"("+people.realName+")"' class="fontOutLine" style="font-size: 30px;">
								{{"("+people.realName+")"}}
							</span>
						</div>
						<div v-else class="fontDiv">
							<span :data-content='people.realName' class="fontOutLine"
								style="font-size: 50px;vertical-align: top;">
								{{people.realName}}
							</span>
						</div>

						<div class="fontDiv">
							<span :data-content="people.school+' '+people.major+' '+people.education"
								class="fontOutLine" style="font-size: 30px;">
								{{people.school+' '+people.major+' '+people.education}}
							</span>
						</div>
						<div class="fontDiv">
							<span :data-content="people.team+'小组 '+people.duty" class="fontOutLine"
								style="font-size: 30px;">
								{{people.team+'小组 '+people.duty}}
							</span>
						</div>
						<div class="fontDiv">
							<span :data-content="people.birthday" class="fontOutLine" style="font-size: 30px;">
								{{people.birthday}}
							</span>
						</div>
						<div class="fontDiv">
							<span :data-content="people.introduction" class="fontOutLine" style="font-size: 30px;">
								{{people.introduction}}
							</span>
						</div>


					</div>
					<el-image :src="$VUE_APP_PIC_API+people.avatar+''" height="100%" fit="contain"
						style="position: absolute;right: 0;bottom: 0;height: 100%;">
					</el-image>
				</el-carousel-item>
			</el-carousel>
		</div>
		<el-dialog v-model="dialogVisibleInvite" title="" width="90%" append-to-body=false class="element2" top="5vh"
			style="max-width: 1000px;padding: 50px;">
			


				<div style="" v-html="openTeam.contentInvite"></div>
		

		</el-dialog>


	</div>
</template>

<script>
	import {
		inject,
		ref,
		onMounted,
		getCurrentInstance
	} from "vue";


	import {
		generalGet,
		generalCount,
		generalGetOne
	} from '@/api/general'

	// import ResizeMixin from'./mixin/ResizeHandler'
	export default {

		components: {

		},
		// mixins: [ResizeMixin],
		computed: {


		},
		setup(props, context) {
			const {
				proxy
			} = getCurrentInstance();

			const openPeople = function(user) {
				console.log(user)
				var _this = this
				proxy.$refs.carousel.setActiveItem(user.id);
				setTimeout(function() {
					_this.dialogVisible = true
				}, 1)

			}

			// onmount
			// const VUE_APP_PIC_API= inject('global').$VUE_APP_PIC_API
			return {

				// VUE_APP_PIC_API:VUE_APP_PIC_API,
				// carousel:ref(),
				openTeam: ref({}),
				dialogVisibleInvite: ref(false),
				dialogVisible: ref(false),
				teamList: ref({}),
				peopleList: ref([]),
				canWhell: true,
				moveSectionUp: function() {
					context.emit('update:moveSectionUp');
				},
				moveSectionDown: function() {
					context.emit('update:moveSectionDown');
				},
				openPeople: openPeople,


			};
		},


		created() {
			var _this = this
			generalGet("teams/list", {
				filter: {
					where: {
						state: 1
					}
				}
			}).then(response => {

				_this.teamList = response
				var peopleList = []
				for (var i in response.list) {
					for (var j in response.list[i].users) {
						var _user = response.list[i].users[j]
						_user.team = response.list[i].name
						_user.birthday = this.$moment(_user.birthday).format("YYYY").slice(2, 3) + "0后"
						peopleList.push(_user)
					}
				}
				_this.peopleList = peopleList
				console.log(_this.peopleList)
			}).catch(error => {
				this.$message.error(error)
			})
		},
		methods: {
			invitClick(team) {
				this.openTeam = team
				this.dialogVisibleInvite = true
			},
			closePeople() {
				this.dialogVisible = false
			}

		}

	}
</script>

<style scoped>
	.fontDiv {
		margin-bottom: 10px;
	}

	.fontOutLine {
		/* font-size:50px; */
		-webkit-text-stroke: 10px white;
		color: #ffffff;
		font-weight: bolder;

	}

	.fontOutLine:before {
		content: attr(data-content);
		position: absolute;
		font-weight: bolder;
		-webkit-text-stroke: 0px white;

		color: black;

	}

	.hangTeam {

		/* border-radius: 5px; */
		/* margin-bottom: 5px; */
		height: 35vh;
		width: calc(84vw/4);
		/* display:inline-block; */
		float: left;
		/* width: fit-content; */
		/* background-color: #000000; */
		/* border: solid 1px #000000; */
		margin: 0.5vw;

	}

	.demonstration {
		color: var(--el-text-color-secondary);
	}

	.el-carousel__item h3 {
		color: #475669;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
		text-align: center;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf00;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: #d3dce600;
	}
</style>