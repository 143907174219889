<template>
	<div class="navbar-box">
		<div class="nav" style="margin-top: 20px;" :style="{width:menuShow?'60%':'0%','min-width':menuTitleShow?'400px':'70px'}">
			<div class="nav-padding transition" :style="{display:menuTitleShow?'flex':'none',opacity:menuTitleOpactity?1:0}">
				
				<div class="menu" >
					<a href="#index">
					<span class="menu-bgc" />
					<div class="menu-text">
						首&nbsp;&nbsp;页
					</div>
					</a>
				</div>
				<div class="menu" >
					<a href="#case">
					<span class="menu-bgc" />
					<div class="menu-text">案&nbsp;&nbsp;例</div>
					</a>
				</div>
				<div class="menu" >
					<a href="#product">
					<span class="menu-bgc" />
					<div class="menu-text">产&nbsp;&nbsp;品</div>
					</a>
				</div>
			</div>
			<div class="btn-box"  @click="logoClick()">
				<img class="back-img" src="@/assets/homePage/logo.png" alt="">
			</div>
			<div class="nav-padding transition" :style="{display:menuTitleShow?'flex':'none',opacity:menuTitleOpactity?1:0}">
				<div class="menu" >
					<a href="#people">
					<span class="menu-bgc" />
					<div  class="menu-text">团&nbsp;&nbsp;队</div>
					</a>
				</div>
				<div class="menu"  >
					<a href="#news">
					<span class="menu-bgc" />
					<div class="menu-text">动&nbsp;&nbsp;态</div>
					</a>
				</div>
				<div class="menu" >
					<a href="#about">
					<span class="menu-bgc" />
					<div class="menu-text">我&nbsp;&nbsp;们</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			status: false,
			name: '',
			menuShow:false,
			menuTitleShow:false,
			menuTitleOpactity:false
		};
	},
	created() {
	},
	methods: {
		logoClick(){
			var _this=this
			if(_this.menuShow){
				
				_this.menuTitleOpactity=!_this.menuTitleOpactity
				setTimeout(function(){
					_this.menuTitleShow=!_this.menuTitleShow
					_this.menuShow=!_this.menuShow
				},300)
			}else{
				_this.menuShow=!_this.menuShow
				
				setTimeout(function(){
					_this.menuTitleOpactity=!_this.menuTitleOpactity
					_this.menuTitleShow=!_this.menuTitleShow
				},300)
			}
			
			
		}
	}
};
</script>

<style lang="scss" scoped>
* {
	padding: 0;
	margin: 0;
}

.navbar-box {
	
	display: flex;
	justify-content: center;
	align-items: center;
}

// .nav {
// 	display: flex;
// 	justify-content: space-around;
// 	align-items: center;
// 	color: #ffffff;
// 	font-size: 0;
// 	width: 90px;
// 	height: 45px;
// 	background: #DB007F;
// 	border-radius: 31px;
// }

.nav {
	display: flex;
	color: #ffffff;
	justify-content: space-around;
	align-items: center;
	transition: width 0.5s,font-size 0.8s,;	
	width: 60%;
	min-width: 400px;
	height: 36px;
	background: #0776f9;
	border-radius: 36px;
	margin-top: 30px;
	font-size: 14px;
}

.menuShow{
	opacity:1;
}

.nav-padding {
	// opacity: 0;
	width: 40%;
	height: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.nav-right {
	width: 40%;
	height: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

// .btn-box:hover {
// 	margin-top: 0px;
// 	width: 123px;

// }

.menu-text{
	width: 50px;
	text-align: center;
}

.menu-text:hover{
	color: #DB007F;
}

.btn-box:hover{
	cursor: pointer;
}

.btn-box {
	width: 70px;
	height: 34px;
	background: #ffffff;
	border-radius: 31px;
	border-color: #009CE3;
	border: 1px solid #009CE3;
	margin-top: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}


.back-img {
	// width: 30px;
	height: 22px;

}

.menu a{
	 color: inherit;
	  text-decoration: none;
}


.menu:hover {
	cursor: pointer;
	.menu-bgc {
		width: 50px;
		height: 4px;
		background: #DB007F;
		border-radius: 9px 9px 0px 0px;
		position: absolute;
		bottom: 0;
		
		// left: 0;
	}
}


.transition {
	transition: 2s opacity ease;
}
</style>