<template>
	<div style="top: 0;position: absolute;z-index: -1;">
		<div style="position: fixed;left: 0;width: 100vw;">
			<div v-for="(w, i) in list" :key="i">
				<div style="margin: 2.5px auto;height: 25px;"
					:style="{ width: (blockSize + margin) * w.length + 'px' }">
					<div v-for="(h, j) in w" :key="j" class="block transition" :class="h.class"
						:style="{ 'opacity': h.opacity }">
					</div>
				</div>
			</div>
		</div>
		<!-- <div style="margin: 0 auto;max-width: 300px;">
			<img src="/logo.png" style="width: 300px;"
				:style="{ 'margin-top': imgHeight + 'px', 'margin-left': imgWidth + 'px' }" />
		</div> -->
		<!-- <div style="margin: 20px auto;width: 600px;max-width: 90vw;" v-show="showTitle">
			<img class="transitionShow" :class="showTitle" src="/title.png" style="width: 100%;" :style="{}">
			<div style="padding-top: 60px;display: flex;justify-content: center;" v-show="showTitle">
				<div class="home-btn" :class="showTitle" @click="jumpHomePage()">
					进入首页
				</div>
			</div>
		</div> -->
	</div>
</template>
<script>
	// import fullpage from 'fullPage.js'
	import { reactive  } from 'vue'
	var _this;
	export default {
		name: 'backGround',
		mounted() {
			window.addEventListener('resize', this.refreash);
		},
		created() {
			_this = this
			this.refreash()

			update()

			function update() {
				_this.interval = setTimeout(function() {
					// _this.time += _this.time * 0.2
					// console.log(_this.time)
					// if (_this.time > _this.timeSum) {
					// 	clearInterval(_this.interval);
					// 	_this.showTitle = true
					// 	return
					// }
					_this.refreash()
					update()

				}, 200 + _this.time)
			}
		},
		data() {
			return {
				showTitle: "titleHide",
				timeSum: 1000,
				time: 20,
				// list: [],
				blockSize: 25,
				margin: 2.5,
				color: ["#009CE3", "#0051A4", "#DB007F"],
				colorList: ["darkBlue", "lightBlue", "lightGreen"],
				imgHeight: 0,
				imgWidth: 0
			}
		},
		
		setup() {
		  const list =  reactive ([]);
		  return {
		    list // 导出响应式数组
		  }
		},
		methods: {
			refreash() {
				// console.log(this.time/this.timeSum)
				var _w = parseInt(window.innerWidth / (this.blockSize + this.margin));
				var _h = parseInt(window.innerHeight / (this.blockSize + this.margin));
				this.imgWidth = parseInt(_w % 2 + (_w % 2 ? 0 : 1) * (this.blockSize + this.margin)) / -2

				this.imgHeight = parseInt(_h - 10 + (_h % 2 ? 1 : 0)) / 2 * (this.blockSize + this.margin) + this.margin;
				// var list =JSON.parse(JSON.stringify(this.list))  
				var list =this.list
				for (var i = 0; i < _h; i++) {
					var _list = list[i]
					if (!_list) {
						list.push([])
						_list = list[i]
					}
					
					for (var j = 0; j < _w; j++) {
						var __list = _list[j]
						if (!__list) {
							_list.push({
								opacity: Math.random() * 0.05,
								// color: this.color[Math.floor(Math.random()*3)],
								// opacity: (this.timeSum - this.time) / this.timeSum * Math.random() * 0.9,
								class: this.colorList[Math.floor(Math.random() * 2.1)]
							})
						}

						if (Math.random() > 0.8) {
							// _list[j]={
							// 	opacity: Math.random() * 0.1,
							// 	// color: this.color[Math.floor(Math.random()*3)],
							// 	// opacity: (this.timeSum - this.time) / this.timeSum * Math.random() * 0.9,
							// 	class: this.colorList[Math.floor(Math.random() * 2.1)]
							// }
							list[i][j].opacity=Math.random() * 0.05
							list[i][j].class=this.colorList[Math.floor(Math.random() * 2.1)]
							// this.$set(_list,j,{
							// 	opacity: Math.random() * 0.1,
							// 	// color: this.color[Math.floor(Math.random()*3)],
							// 	// opacity: (this.timeSum - this.time) / this.timeSum * Math.random() * 0.9,
							// 	class: this.colorList[Math.floor(Math.random() * 2.1)]
							// })
							// __list = {
							// 	opacity: Math.random() * 0.1,
							// 	// color: this.color[Math.floor(Math.random()*3)],
							// 	// opacity: (this.timeSum - this.time) / this.timeSum * Math.random() * 0.9,
							// 	class: this.colorList[Math.floor(Math.random() * 2.1)]
							// }

							// __list.push()
						}
						// else{
						// 	console.log()	
						// }
					}
					// list.push(_list)
				}

				this.list = list
			},
			ChangeWindow() {
				this.refreash()

				// let ratio = this.getRatio();
				// let Pwidth = window.screen.width*this.getRatio()/100;
				// let PHeight = window.screen.height*this.getRatio()/100;
				//  //1920 *1080
				// if (ratio == 100) {
				//   if (Pwidth == '1920' && PHeight == '1080') {
				//     this.$el.style.setProperty('--mg_top1', '3.5vh')
				//   }
				// }
			},
			jumpHomePage() {
				console.log(12312312);
				this.$router.push('/dashboard')
			}
		}
	}
</script>

<style>
	div {
		padding: 0;
		margin: 0;
	}

	span {
		/* line-height: 50%; */
		padding: 0;
		margin: 0;
	}

	.hang {
		height: 25px;
	}

	.block {
		width: 25px;
		height: 25px;
		/* background-color: #0776f9; */
		border-radius: 2.5px;
		display: inline-block;
		/* float: left; */
		margin: 0px 1.25px 0px 1.25px;
		transition: true;
	}

	.transition {
		transition: 0.2s background-color ease;
	}

	.darkBlue {
		background-color: #3fa9f5;
	}

	.lightBlue {
		background-color: #0776f9;
	}

	.lightGreen {
		background-color: #DB007F;
	}

	.transitionShow {
		transition: 2s opacity ease;
	}

	.titleHide {
		opacity: -1;
	}

	.titleShow {
		opacity: 1;
	}

	.home-btn {
		width: 200px;
		height: 50px;
		text-align: center;
		border: 1px solid #0776f9;
		border-radius: 50px;
		line-height: 50px;
		position: fixed;
		z-index: 99;
		cursor: pointer;
		transition: 2s opacity ease;
	}

	.home-btn:hover {
		background-color: #0776f9;
		color: #fff;
		transition: background-color 1s, color 1s
	}
</style>