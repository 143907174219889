<template>
	<section class="app-main">
	  <transition name="fade-transform" mode="out-in">
	    <!-- <router-view :key="key" /> -->
		 <full-page ref="fullpage" :options="options" id="fullpage" :skip-init="true">
			 
		    <indexPage class="section">
				
			</indexPage>
			<casePage class="section">
				
			</casePage>
		    <productPage @update:moveSectionUp="moveSectionUp" @update:moveSectionDown="moveSectionDown" class="section">
		    	
		    </productPage>
			<peoplePage class="section">
				
			</peoplePage>
			<newsPage class="section">
				
			</newsPage>
			<aboutPage class="section">
				
			</aboutPage>
			
			 <div class="section fp-auto-height" id="section2">
			        <div class="myContent" style="background-color: #009CE3;text-align: center;color: white;font-size: 12px;">
			         <span id="copyright">
			                                     © 2016- 2023 贵州黔驴科技有限公司 &nbsp;&nbsp;
			               </span>
					 <span id="icpInfo" style="">
              <a href="http://beian.miit.gov.cn/" target="_blank">黔ICP备2022005134号</a>
			  <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52011102002831" target="_blank">
			  <img src="/images/police.png">
			  贵公网安备52011102002831号</a>
      </span>
	
			        </div>
			    </div>
		  </full-page>
	  </transition>
	</section>
</template>

<script>
	import indexPage from './pages/indexPage'
	import casePage from './pages/casePage'
	import productPage from './pages/productPage'
	import peoplePage from './pages/peoplePage'
	import newsPage from './pages/newsPage.vue'
	import aboutPage from './pages/aboutPage.vue'
	// import 'vue-fullpage.js/dist/style.css'
	// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
	import VueFullPage from 'vue-fullpage.js'
	export default{
	  name: 'AppMain',
	  components: {
	  	indexPage,
		casePage,
		productPage,
		peoplePage,
		newsPage,
		aboutPage
	  },
	  computed: {
	    key() {
			// console.log(this.$route)
			// console.log('this.$route.path',this.$route.path);
	      // return this.$route.path
		  return "1"
	    }
	  },
	  data() {
	      return {
	        options: {
	          licenseKey: 'gplv3-license',
	          menu: '#menu',
			   slidesNavigation: true,
			   // slidesNavPosition:'center',
			  navigation: true,
			          // navigationPosition: 'right',
	          anchors: ['index', 'case', 'product','people','news','about','foot'],
	          sectionsColor: [],
			  scrollBar:false,
			  normalScrollElements:'.map,.element2,.el-overlay-dialog',
			      afterSlideLoad: function( section, origin, destination, direction, trigger){

					  console.log(origin.item.getElementsByTagName('video'))
					  var videos=origin.item.getElementsByTagName('video')
					  if(videos.length>0){
						  videos[0].play()
					  }
			      }
	        },
	      }
	    },
		created() {
			
		},
		mounted(){
			this.componentsReady() 
		},
		methods: {
		  // Called when your components are ready. That is up to you to decide when.
		  componentsReady() {
		    this.$refs.fullpage.init()
			setTimeout(function(){
				var licenseKey=document.getElementsByClassName("fp-watermark")
				console.log()
				if(licenseKey.length){
					licenseKey[0].style.display="none"
				}
				var licenseKey1=document.getElementsByClassName("fp-watermark1")
				console.log()
				if(licenseKey1.length){
					licenseKey1[0].style.display="none"
				}
				
				// licenseKey
			},100)
		  },
		  moveSectionUp(){
			  this.$refs.fullpage.api.moveSectionUp();
		  },
		  moveSectionDown(){
			  this.$refs.fullpage.api.moveSectionDown();
		  }
		}
	}
</script>

<style >
	.el-dialog__body{
		padding: 0 !important;
	}
	.el-dialog__header{
		display: none;
	}
	.openButton{
		line-height: 20px;letter-spacing: 5px;
		font-size: 12px;
	}
	.openButton:hover{
		color: #0776F9;
		cursor: pointer;
	}
	.openIcon{
		background-image: url('~@/assets/homePage/open0.png');
		width: 20px;
		height: 20px;
		background-size:cover;
		display: inline-block;
		vertical-align: middle;
		margin-left: 5px;
		
	}
	.openButton:hover .openIcon{
		background-image: url('~@/assets/homePage/open1.png');
		cursor: pointer;
	}
	.pageTitle{
		height: 60px;padding-left: 5%;font-size: 30px;font-weight: bolder;letter-spacing: 10px;
	}
	.section{
		height:100%;
	}
	.app-main {
		/*50 = navbar  */
		color: #242933;
		width: 100%;
		position: relative;
		overflow: hidden;
	}
	a{
		  color: inherit;
		  text-decoration: none;
	}
	a:hover{
		color: inherit;
		  text-decoration: none;
	}
	a:visited {
		color: inherit;
		  text-decoration: none;
	}
	a:active {
		color: inherit;
		  text-decoration: none;
	}
</style>
<style lang="scss">
	
	// fix css style bug in open el-dialog
	.el-popup-parent--hidden {
		.fixed-header {
			z-index: 9;
		}
	}
</style>