<template>
	<div :class="classObj" class="app-wrapper">
	  <div class="main-container">
	    <div class="fixed-header" >
	      <navBar />
	    </div>
	    <app-main />
		<backGround />
	  </div>
	</div>
</template>

<script>
import {
  navBar,
  AppMain,
  backGround
}from'./components'
// import ResizeMixin from'./mixin/ResizeHandler'
	export default {
	
	components: {
		navBar,
		AppMain,
		backGround
	},
	// mixins: [ResizeMixin],
	computed: {
	  classObj() {
	    return{
	      // hideSidebar: !this.sidebar.opened,
	      // openSidebar: this.sidebar.opened,
	      // withoutAnimation: this.sidebar.withoutAnimation,
	      // mobile: this.device === 'mobile'
	    }
	  }
	},
	created() {
		
		
	},
	methods: {
	  // handleClickOutside() {
	  //   this.$store.dispatch('app/closeSideBar', {
	  //     withoutAnimation: false
	  //   })
	  // }
	}
}
</script>

<style lang="scss" scoped>

	.app-wrapper {
		position: relative;
		height: 100%;
		width: 100%;

		&.mobile.openSidebar {
			position: fixed;
			top: 0;
		}
	}

	.drawer-bg {
		background: #000;
		opacity: 0.3;
		width: 100%;
		top: 0;
		height: 100%;
		position: absolute;
		// z-index: 999;  //解决页面缩小出现蒙版问题
	}

	.fixed-header {
		position: fixed;
		opacity: 1;
		top: 0;
		right: 0;
		z-index: 9;
		width:100%;
		transition: width 0.28s;
	}

	.hideSidebar .fixed-header {
		width: calc(100% - 50px)
	}

	.mobile .fixed-header {
		width: 100%;
	}
</style>
