<template>
	<div style="background-color: #44444400;padding:60px 0px 0 0px;">
		<div class="pageTitle">我们</div>
		<el-image src="/images/about0.png" style="width: 3vw;position: absolute;"></el-image>
		<el-image src="/images/about1.png" style="width: 40vw;position: absolute;bottom: 10px;right: 0;"></el-image>
		<div class="slide"  data-anchor="about1" style="height:calc(100vh - 180px);">
			<div style="width: 100vw;">
				<div style="margin: 0 auto;width: 60%;line-height: 30px;letter-spacing: 5px;">
						<el-image src="/images/logoB.png" style="width: 50%;margin: 0 25%;"></el-image>
					<p style="">
						黔驴科技是一家专注于大数据分析、地理信息、人工智能、混合现实、物联网、高性能计算等技术进行研究与应用的高新技术企业，
						为用户提供专业的技术服务和整体解决方案。
					</p>
					<p>	
						创始团队2011年在贵州大学理学院组建，2016年成立贵州黔驴科技有限公司，
						经过多年的技术沉淀与发展，公司注册商标19项，专利 3 项，计算机软件著作权登记30余项，参与省级科研项目 10 余项，完成技术研发项目 100 余项。
						分别获得贵州省高新技术企业、贵州省科技型中小企业、贵州省创新型中小企业、贵州省双软企业、贵州省数据经济实体经济融合试点单位、贵州省工业互联网服务商、贵阳市见习基地单位、企业知识产权管理体系认证、ISO27001信息安全体系标准认证、DCMM数据管理能力成熟度贯标认证、海光产业生态合作组织成员单位等荣誉与认证。 
					</p>
					<p>
						公司以高新技术为起点、以技术研发为核心、以完善的服务体系为后盾，秉承稳固与发展、求是与创新的精神，已为多家企事业单位提供了全方位的技术服务，在实现企业价值最大化的过程中，积极布局与完善产品设计与研发、平台建设与运营的人才培养和技术创新体系，并取得了很好地效果和获得广泛合作伙伴的赞誉与认同。
					</p>
					
					
				</div>
				
			</div>
			
		</div>
		<div class="slide"  data-anchor="about2" style="height:calc(100vh - 180px);width: 70vw;">
			<div style="margin:0 20%;height: 100%;">
				<el-row :gutter="20" >
				  <el-col :span="10">
					  <el-image src="https://qroom.oss-cn-chengdu.aliyuncs.com/images/2023/12/25/32BsYEE3b4zBNsKhXD60.jpg"
					  style="height: 70vh;margin: 0 auto;max-width: 100%;float: right;" fit="cover"></el-image>
				  </el-col>
				  <el-col :span="14">
					
					<el-image height="25vh" src="https://qroom.oss-cn-chengdu.aliyuncs.com/images/2023/12/25/EK6fhCKwq7srZCblgkVY.jpg"
					style="margin: 0 auto;max-height: 25vh;margin-bottom: 15px;" fit="cover"></el-image>
					<el-image src="https://qroom.oss-cn-chengdu.aliyuncs.com/images/2023/12/25/5o1LgRoeUb9gHknvqXyf.jpg"
					style="margin: 0 auto;max-height: 25vh;" fit="cover"></el-image>
					<p>
						贵州省 贵阳市 花溪区 贵州大学 北校区 
						</p>
						<p>	
						-教职工宿舍 Qroom
						</p>
						<p>	
						-逸夫楼5楼 红色文化虚拟仿真体验教学中心
						</p>
						<p>	
						-博学楼9楼 超级计算算力算法应用实验室
					
					</p>
					</el-col>
				</el-row>
			</div>		
		</div>
		<div class="slide"  data-anchor="about2" style="height:calc(100vh - 180px);width: 70vw;">
			<div style="margin:0 10%;height: 100%;">
				<el-image  src="https://qroom.oss-cn-chengdu.aliyuncs.com/images/2023/12/25/RNToYzF2U89UjZhjsiit.jpg"
				style="margin: 0 auto;width: 100%;height: 99%;" fit="contain"></el-image>
			</div>		
		</div>
		

	</div>
</template>

<script>
	import {
		inject,
		ref
	} from "vue";


	// import ResizeMixin from'./mixin/ResizeHandler'
	export default {

		components: {

		},
		// mixins: [ResizeMixin],
		computed: {


		},
		setup(props, context) {
				// const VUE_APP_PIC_API= inject('global').$VUE_APP_PIC_API
			return {
				
				canWhell: true,
				moveSectionUp: function() {
					context.emit('update:moveSectionUp');
				},
				moveSectionDown: function() {
					context.emit('update:moveSectionDown');
				}
			};
		},


		created() {
			var _this = this

		},
		methods: {
			rollImg(e) {
				
			}

		}

	}
</script>

<style scoped>
	.blockP {
		line-height: 0;
		margin: 0;
	}

	.block {
		width: 25px;
		border-radius: 5px;
		display: inline-block;
		height: 25px;
		margin: 0;
		padding: 0;

		border: 1.25px solid white;
		line-height: 0;
	}

	.green {
		background-color: #DB007F;
	}

	.gray {
		background-color: #B8B8B8;
	}

	.blue1 {
		background-color: #2A9CF5;
	}

	.blue2 {
		background-color: #0776F9;
	}

	.alpha {
		opacity: 0;
	}

	
</style>
