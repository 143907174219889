<template>
	<div style="background-color: #44444400;padding:0px 0px 0 0px;height: 100vh;">
		<div class="pageTitle" style="position: absolute;top: 60px;">案例</div>
		<div class="slide" v-for="(item,index) in slideList" :data-anchor="index" v-bind:key="index"
			style="height:calc(100vh - 180px);">

			<el-row style="width: 90%;margin: 60px 5% 0px 5%;height:calc(100vh - 180px);">
				<el-col :span="20" style="height: 100%;">
					<div v-if="item.type=='video'" style="height: 99%;">
						<video :src="$VUE_APP_PIC_API+item.imgUrl" muted autoplay loop style="height: 100%;"
							:style="{'background-color':item.bgColor}">
							您的浏览器不支持 video 标签。
						</video>

					</div>
					<div v-if="item.type=='image'" style="height: 99%;">

						<el-image style="width: 100%;height: 100%;border-radius: 5px;"
							:src="$VUE_APP_PIC_API+item.imgUrl" :fit="'cover'" />
					</div>
				</el-col>
				<el-col :span="4" style="text-align: right;" class="title">
					<div style="position: absolute;bottom: 10%;right: 0;">
						<p style="font-size: 20px;font-weight: bold;letter-spacing: 2px;margin: 0;">{{item.name}}</p>
						<p style="margin-top: 0;">{{item.subName}}</p>
						<a v-if="item.linkType==2" :href="item.linkUrl" target="_blank">
							<div class="openButton" style="">
								<span>查看详情</span>
								<span class="openIcon" style=""></span>
							</div>
						</a>
						<p class="blockP"><span class="block alpha" /></p>

						<p class="blockP"><span class="block blue1" /></p>

						<p class="blockP"><span class="block blue1" /><span class="block blue2" /></p>

					</div>

				</el-col>
			</el-row>
			<!-- <div style="width:calc((100vh - 240px)/9*16);height:calc(100vh - 240px);padding-left: 80px;display:inline-block;">
				<div v-if="item.type=='video'"  style="height: 99%;">
						<video :src="item.url" muted autoplay loop style="height: 100%;" >
						您的浏览器不支持 video 标签。
						</video>
				
				</div>
				<div v-if="item.type=='image'"  style="height: 99%;">
						
						<el-image style="width: 100%;height: 100%;" :src="item.url" :fit="'cover'" />
				</div>
			</div>
			<div style="display: inline-block;position: absolute;text-align: right;bottom: 30px;float: right;width: 200px;">
				<p>{{item.title}}</p>
				<p class="blockP"><span class="block alpha" /></p>
				<p class="blockP"><span class="block gray" /></p>
				<p class="blockP"><span class="block blue1" /></p>
				<p class="blockP"><span class="block blue2" /><span class="block alpha" /></p>
				<p class="blockP"><span class="block blue1" /></p>
				<p class="blockP"><span class="block alpha" /></p>
			</div> -->
		</div>




	</div>
</template>

<script>
	import {
		inject,
		ref
	} from "vue";
	import {
		generalGet,
	} from '@/api/general'

	// import ResizeMixin from'./mixin/ResizeHandler'
	export default {

		components: {

		},
		// mixins: [ResizeMixin],
		computed: {


		},
		setup() {
			// const VUE_APP_PIC_API = inject('global').$VUE_APP_PIC_API
			return {
				// VUE_APP_PIC_API: VUE_APP_PIC_API,
				slideList: ref([
					// {
					// 	type: 'video',
					// 	url: VUE_APP_PIC_API+'/images/suanli.mp4',
					// 	bgColor:'#E4ECFA',
					// 	title:"贵安新区算力运营"
					// },
					// {
					// 	type: 'image',
					// 	url: VUE_APP_PIC_API+'/images/jidi.jpg',
					// 	title:"数字内容制作基地"
					// },
					// {
					// 	type: 'image',
					// 	url: VUE_APP_PIC_API+'/images/yiliao.png',
					// 	title:"智慧家庭医生平台"
					// }
				]),

			};
		},


		created() {
			var _this = this

			// const video = document.querySelector('video');
			generalGet("cases", {
				filter: {
					where: {
						state: 1
					},
					order: 'index DESC',
					fields: {
						linkType: true,
						linkUrl: true,
						index: true,
						type: true,
						bgColor: true,
						imgUrl: true,
						subName: true,
						name: true,
					},
				}
			}).then(response => {

				_this.slideList = response

				console.log(_this.slideList)
			}).catch(error => {
				this.$message.error(error)
			})


		},
		methods: {
			vid(index) {

			},
			moveImg(e) {

			},
			rollImg(e) {

			}

		}

	}
</script>

<style scoped>
	
	.blockP {
		line-height: 0;
		margin: 0;
	}

	.block {
		width: 25px;
		border-radius: 5px;
		display: inline-block;
		height: 25px;
		margin: 0;
		padding: 0;

		border: 1.25px solid white;
		line-height: 0;
	}

	.green {
		background-color: #DB007F;
	}

	.gray {
		background-color: #B8B8B8;
	}

	.blue1 {
		background-color: #2A9CF5;
	}

	.blue2 {
		background-color: #0776F9;
	}

	.alpha {
		opacity: 0;
	}

	video {
		background-color: white;
		border: none;
		width: 100%;
		height: 100%;
		object-fit: contain;
		border-radius: 5px;
	}

	video:focus {
		outline: none;
	}

	img {
		border-radius: 5px;
	}
</style>