<template>
	<div style="background-color: #44444400;padding:60px 0px 0 0px;">
		<div class="pageTitle">产品</div>
		<div id="productBox" class="element2" @mousewheel.prevent="rollImg"
			style="width: 100vw;margin: 0 0% 0 0%;height:calc(100vh - 180px);overflow-x:scroll;">
			<div id="" :style="{'width':'calc(60vh * '+slideList.length+')'}" style="padding: 0 0 0 10vh;">
				<div v-for="(item,index) in slideList" :data-anchor="index" v-bind:key="index"
					style="width:calc(50vh);display: inline-block;margin:0 10vh 0 0;">
					<el-image style="width:calc(50vh);height: calc(50vh);border-radius: 5px;" :src="$VUE_APP_PIC_API+item.imgUrl+'?x-oss-process=image/resize,m_fill,h_'+innerHeight+',w_'+innerHeight+''"
						:fit="'cover'" />
					<div style="font-size: 20px;line-height: 60px;font-weight: bold;letter-spacing: 2px;">{{item.name}}</div>
					<a v-if="item.linkType==2" :href="item.linkUrl" target="_blank">
						<div class="openButton" style="">
							<span>查看详情</span>
							<span class="openIcon" style=""></span>
						</div>
					</a>
					<div v-else class="openButton" style="">
						<span>&nbsp;</span>
						
					</div>
					
				
				</div>
			</div>


		</div>



	</div>
</template>

<script>
	import {
		inject,
		ref
	} from "vue";
import {
			generalGet,
		} from '@/api/general'

	// import ResizeMixin from'./mixin/ResizeHandler'
	export default {

		components: {

		},
		// mixins: [ResizeMixin],
		computed: {


		},
		setup(props, context) {
				// const VUE_APP_PIC_API= inject('global').$VUE_APP_PIC_API
			return {
				// VUE_APP_PIC_API:VUE_APP_PIC_API,
				slideList: ref([
					
				]),
				innerHeight:parseInt(window.innerHeight),
				canWhell: true,
				moveSectionUp: function() {
					context.emit('update:moveSectionUp');
				},
				moveSectionDown: function() {
					context.emit('update:moveSectionDown');
				}
			};
		},


		created() {
			var _this = this
			generalGet("products", {
				filter: {
					where: {
						state: 1
					}
				}
			}).then(response => {
			
			_this.slideList=response
				
			console.log(_this.slideList)
			}).catch(error => {
				this.$message.error(error)
			})
			
			console.log(window.innerHeight)
		},
		methods: {
			rollImg(e) {
				var _this = this
				let div = document.getElementById("productBox");
				let left = -e.wheelDelta || e.deltaY / 2;

				// console.log('wheelDelta:',-e.wheelDelta,"deltaY :" , e.deltaY);
				var _scrollLeft = div.scrollLeft - div.childNodes[0].scrollWidth + div.clientWidth;

				console.log(_scrollLeft)
				// console.log()
				console.log(div.scrollLeft)
				if ((div.scrollLeft == 0) && (left < 0) && this.canWhell) {
					this.moveSectionUp()
					this.canWhell = false
					setTimeout(function() {
						_this.canWhell = true
					}, 1000)
				} else if ((_scrollLeft == 0) && (left > 0) && this.canWhell) {
					this.canWhell = false
					this.moveSectionDown()
					setTimeout(function() {
						_this.canWhell = true
					}, 1000)
				}
				div.scrollLeft = div.scrollLeft + left;
			}

		}

	}
</script>

<style scoped>
	.blockP {
		line-height: 0;
		margin: 0;
	}

	.block {
		width: 25px;
		border-radius: 5px;
		display: inline-block;
		height: 25px;
		margin: 0;
		padding: 0;

		border: 1.25px solid white;
		line-height: 0;
	}

	.green {
		background-color: #DB007F;
	}

	.gray {
		background-color: #B8B8B8;
	}

	.blue1 {
		background-color: #2A9CF5;
	}

	.blue2 {
		background-color: #0776F9;
	}

	.alpha {
		opacity: 0;
	}

	/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
	::-webkit-scrollbar {
		/* background-color: #f5f5f5; */
		width: 10px;
	}

	/*定义滚动条轨道 内阴影+圆角*/
	::-webkit-scrollbar-track {
		/* -webkit-box-shadow: inset 0 0 6px 6px rgba(0, 0, 0, 0.1); */
		/* border-radius: 10px; */
		/* background-color: #f5f5f500; */
	}

	/*定义滑块 内阴影+圆角*/
	::-webkit-scrollbar-thumb {
		/* border-radius: 10px; */
		/* -webkit-box-shadow: inset 0 0 6px 6px rgba(0, 0, 0, 0.3); */
		background-color: #DB007F;
	}
	
	::-webkit-scrollbar-button{
		width: 5%;
	}
</style>
