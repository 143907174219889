<template>
	<div style="background-color: #44444400;padding:80px 80px 0 80px;">


		<div class="map" style="">
			<!-- <el-image style="width: 120%; height: 120%" :src="'map1.jpg'" :fit="'contain'" /> -->
			<div ref="imgWrap" class="wrap" @mousewheel.prevent="rollImg" style="background-color: #E5EFF8;">
				<img :src="$VUE_APP_PIC_API+'guanwang/images/ditu0.jpg'" alt
				 ref="image" @mousedown.prevent="moveImg" style="transform:scale(0.15)"/>
			</div>
		</div>
		<div id="" style="width: 40%;" class="">

			<img src="@/assets/homePage/name.png" style="width:200px;max-width:80%;">


			<p class="title2" style="">人工到智能的数字技术服务商</p>
			<!-- <div style="border-left:0px solid #222222;height: 100px;margin: 20px;"></div> -->
			<p class="blockP"><span class="block alpha" /></p>
			<p class="blockP"><span class="block gray" /></p>
			<p class="blockP"><span class="block blue1" /></p>
			<p class="blockP"><span class="block alpha" /><span class="block blue2" /></p>
			<p class="blockP"><span class="block blue1" /></p>
			<p class="blockP"><span class="block alpha" /></p>
			<!-- <div class="block gray" /><div class="block gray" /><div class="block gray" /><br/>
			<div class="block alpha" /><div class="block alpha" /><div class="block blue1" /><br/>
			<div class="block alpha" /><div class="block gray" /><div class="block gray" /><br/>
			<div class="block alpha" /><div class="block gray" /><br/>
			<div class="block alpha" /><br/>
			<div class="block alpha" /><div class="block gray" /><br/> -->
			<div class="gongshi">
				<div style="font-size: 20px;margin-bottom: 20px;">您需要：</div>
				<div style="text-align: center;">
					<span @mouseover="gongshiOn(3)"  :style="{'background-color':gongshiInt>2?'#009CE3':''}">数据</span>+<span
						@mouseover="gongshiOn(2)" :style="{'background-color':gongshiInt>1?'#0051A4':''}">模型</span>+<span
						@mouseover="gongshiOn(1)" :style="{'background-color':gongshiInt>0?'#DB007F':''}">算力</span>= 智能
				</div>
				
			</div>

			<p class="blockP"><span class="block alpha" /></p>
			<!-- <p class="blockP"><span class="block alpha" /></p> -->
			<p class="blockP"><span class="block alpha" /></p>
			<!-- <p class="blockP"><span class="block green" /></p> -->
		
				<div class="jieshao" v-if="gongshiInt==3">
					<span class="block blue1" style="vertical-align: middle;"></span>
					<span class="title">
					全生命周期的数字化服务</span>
					<p>协助您从业务出发，对自己的各类系统进行梳理，开发、升级、优化、集成。</p>
					<p>对数据资产进行本地备份和异地容灾，保证数据资产安全。</p>
					<p>打通数据孤岛，构建数据中台。利用数据价值，提升业务能力。</p>
					<div>
						<!-- <span class="link">业务合作</span> -->
						</div>
				</div>
				
				<div class="jieshao" v-if="gongshiInt==2">
					<span class="block blue2" style="vertical-align: middle;"></span>
					<span class="title">
					AI智能模型服务</span>
					<p>对数据进行分析处理，可视化构建。</p>
					<p>联合科研力量基于数据构建各类算法模型。</p>
					<p>将算法模型封装成为工程化应用，结合算力池，对外提供SaaS服务。</p>
					<div>
						<!-- <span class="link">业务合作</span> -->
						</div>
				</div>
				
				<div class="jieshao" v-if="gongshiInt==1">
					<span class="block green" style="vertical-align: middle;"></span>
					<span class="title">
					数在贵安 安心计算</span>
					<p>放心 · 省心 · 可信</p>
					<p>十分安全。数据安全，计算安全，传输安全。</p>
					<p>一站式服务，简单便捷。多云异构存算池，计算一条龙服务。</p>
					<p>第三方算力。中立算力中心，政府背书。</p>
					
					<div>
						<!-- <span class="link">算力商城</span>
						<span class="link">业务合作</span>
						<span class="link">抖音</span>
						<span class="link">公众号</span> -->
						</div>
				</div>

			

			<!-- <div style="position: absolute;bottom: 85px;right: calc(60% + 12px);">
				<div style="" class="option" v-for="n in [1,2,3,4,6,7]" :key="n">
					
					<img src="icon1.png" style="width: 100%;">
							
				</div>
				
			</div> -->

		</div>

	</div>
</template>

<script>
	import {
		ref
	} from 'vue'
	// import ResizeMixin from'./mixin/ResizeHandler'
	export default {

		components: {

		},
		// mixins: [ResizeMixin],
		computed: {


		},
		setup() {

			// 将 ref 暴露给模板
			return {
				imgWrap: ref(null),
				image: ref(null),
				gongshiInt: ref(0)
			}
		},
		created() {
			this.imgWrap = ref(null)

		},
		methods: {
			gongshiOn(index) {
				console.log(index)
				this.gongshiInt = index;
			},
			moveImg(e) {
				var _this = this
				let wrap = this.$refs.imgWrap

				let img = this.$refs.image
				let x = e.pageX - img.offsetLeft
				console.log(x)
				let y = e.pageY - img.offsetTop
				// 添加鼠标移动事件
				wrap.addEventListener('mousemove', move)

				function move(e) {
					_this.$refs.image.style.left = e.pageX - x + 'px'
					_this.$refs.image.style.top = e.pageY - y + 'px'
				}
				// 添加鼠标抬起事件，鼠标抬起，将事件移除
				img.addEventListener('mouseup', () => {
					wrap.removeEventListener('mousemove', move)
				})
				// 鼠标离开父级元素，把事件移除
				wrap.addEventListener('mouseout', () => {
					wrap.removeEventListener('mousemove', move)
				})
			},
			rollImg(e) {
				let transform = this.$refs.image.style.transform
				let zoom = transform.indexOf("scale") != -1 ? +transform.split("(")[1].split(")")[0] : 1
				zoom += e.wheelDelta / 1200
				console.log(zoom)
				if (zoom > 0.1 && zoom < 1) {
					this.$refs.image.style.transform = "scale(" + zoom + ")"
				}
			}

		}

	}
</script>

<style scoped>
	.blockP{
		line-height: 0;
		margin: 0;
	}
	.block{
		width: 25px;
		border-radius: 5px;
		display: inline-block;
		height: 25px;
		margin: 0;
		padding: 0;
		
		border: 1.25px solid white;
		line-height: 0;
	}
	.green{
		background-color:#DB007F;
	}
	.gray{
		background-color: #B8B8B8;
	}
	.blue1{
		background-color: #0051A4;
	}
	.blue2{
		background-color: #009CE3;
	}
	.alpha{
		opacity: 0;
	}
	
	.jieshao {
		font-weight: normal;
		font-size: 16px;
		/* margin-top: 100px; */
		color: #484848;
		line-height: 200%;
		
	}
	.jieshao .title{
		font-size: 20px;font-weight: bold;line-height: 25px;vertical-align: middle;
	}
	
	.jieshao .link{
		margin:0 50px 0 0;
		font-size: 24px;
		font-weight: bold;
		line-height: 80px;
		cursor: pointer;
	}

	.gongshi {
		/* margin-top: 100px; */
		font-size: 26px;
		font-weight: bolder;
		color: #333333;
		cursor: default;
		/* width: 100%; */
	}

	.gongshi span {
		display: inline-block;
		width: 100px;
		height: 50px;
		text-align: center;
		background-color: #E5EFF8;
		color: #FFFFFF;
		line-height: 50px;
		/* padding: 10px; */
		border-radius: 2.5px;
		margin: 0 10px;
	}

	.gongshi span:hover {
		/* background-color: #DB007F; */
		cursor: pointer;
	}

	.gongshi .active {
		background-color: #DB007F;

	}

	.wrap {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.wrap img {
		position: absolute;
		cursor: move;
	}

	.map {
		width: 60%;
		height: calc(100vh - 150px);
		background-color: #ffffff;
		float: right;
		border: 2.5px solid #ffffff;
		border-radius: 5px;
		overflow: auto;
		overflow-y: hidden;
		overflow-x: hidden;
	}

	.option {
		width: 57.5px;
		height: 57.5px;
		margin-left: 21px;
		/* transform: skewX(-30deg); */
		/* background-color: #0776F9; */
		position: relative;
		border: 2.5px solid #FFFFFF00;
		border-radius: 5px;
		float: right;
		cursor: pointer;
		/* border-color: #0776F9; */
	}

	.option:hover {

		border-color: #DB007F;
	}

	/* .option > div  { transform: skewX(30deg); } */


	p {
		margin: 0;
	}

	.title2 {
		font-size: 20px;
		font-weight: bold;
		transition: all 5s ease;
		background: -o-linear-gradient(left, #009CE3,#0051A4, #8B4D9A, #A90082, #DB007F);
		background: -webkit-linear-gradient(left, #009CE3,#0051A4, #8B4D9A, #A90082, #DB007F);
		-webkit-background-clip: text;
		-moz-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		-webkit-animation: dancingColors 10s infinite forwards ease-in-out;
		animation: dancingColors 10s infinite forwards ease-in-out;
	}

	@-webkit-keyframes dancingColors {
		50% {
			background-position: -860px;
		}
	}

	@keyframes dancingColors {
		50% {
			background-position: -860px;
		}
	}
</style>
