import { createApp } from 'vue'
import App from './App.vue'


import './style.css'
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'
const app = createApp(App)
app.use(VueFullPage)

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
app.use(ElementPlus)

import "video.js/dist/video-js.css";

import moment from 'moment'
moment.defaultFormat = 'YYYY年MM月DD日 HH:mm:ss'
app.config.globalProperties.$moment = moment

app.config.globalProperties.$VUE_APP_PIC_API = process.env.VUE_APP_PIC_API
app.config.globalProperties.$VUE_APP_BASE_API = process.env.VUE_APP_BASE_API
console.log(app.config.globalProperties)

// 配置全局变量 页面中使用 inject 接收
// app.provide('global',{
//   $VUE_APP_PIC_API:process.env.VUE_APP_PIC_API,
//   $VUE_APP_BASE_API:process.env.VUE_APP_BASE_API
// })

app.mount('#app')
