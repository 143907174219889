<template>
	<div style="background-color: #44444400;padding:60px 0px 0 0px;">
		<div class="pageTitle" style="">动态</div>
		<div style="width: 95vw;margin: 0 0% 0 5%;height:calc(100vh - 180px);">

			<el-row>
				<el-col :span="8">
					<div v-if="firstNews.id" style="width: 25vw;">
						<el-image
							:src="$VUE_APP_PIC_API+firstNews.imageUrl+'?x-image-process=image/resize,w_1024,h_1024/quality,q_80'"
							:fit="'cover'" style="width: 25vw;height: 25vw;border-radius: 2.5px;"></el-image>
						<div style="letter-spacing: 3px;width: 25vw;margin: 10px 0;"><span>{{firstNews.title}}</span>
						</div>
						<div style="letter-spacing: 3px;font-size: 12px;color: #00000082;">
							{{$moment(firstNews.publishTime).format('YYYY-MM-DD HH:mm')}}
						</div>
						<div class="openButton" style="" @click="openPage(firstNews.id)">
							<span>
								查看详情
							</span>
							<span class="openIcon" style=""></span>
						</div>
					</div>

				</el-col>
				<el-col :span="16">
					<div class="example-pagination-block" style="width: 100%;">
						<div v-for="news,index in newsList" :key="index" style="margin-bottom: 0px;">
							<el-divider v-if="index!=0" style="width: 90%;" />
							<el-row style="width: 90%;">
								<el-col :span="4">
									<el-image
										:src="$VUE_APP_PIC_API+news.imageUrl+'?x-oss-process=image/resize,m_fill,w_512,h_512'"
										:fit="'cover'" style="width: 8vw;height: 8vw;border-radius: 2.5px;"></el-image>
								</el-col>
								<el-col :span="20">
									<div style="letter-spacing: 3px;"><span>{{news.title}}</span></div>
									<div style="letter-spacing: 3px;font-size: 12px;color: #00000082;margin: 10px 0;">
										{{$moment(news.publishTime).format('YYYY-MM-DD HH:mm')}}
									</div>
									
									<div @click="openPage(news.id)" class="openButton" style="">
										<span>查看详情</span>
										<span class="openIcon" style=""></span>
									</div>
									
								</el-col>
							</el-row>


						</div>
						<el-pagination v-model="pageIndex" align="center" @current-change="changeCurrentOrPage"
							layout="prev, pager, next" :page-count="pageCount"
							style="display: flex;justify-content: center;" />

					</div>
				</el-col>

			</el-row>
		</div>


		
		  <el-dialog
		    v-model="dialogVisible"
		    title=""
		    width="90%"
		    :before-close="handleClose"
			append-to-body=false
			class="element2"
			top="5vh"
			style="max-width: 1000px;"
		  >
		    <div style="padding: 20px 0;">
				<div style="text-align: center;font-weight: bold;font-size: 20px;">
				{{news.title}}
				</div>
				<div style="letter-spacing: 3px;font-size: 12px;color: #00000082;margin: 10px 0;text-align: center;">
				{{$moment(news.publishTime).format('YYYY-MM-DD HH:mm')}}
				</div>
				<div style="margin:0 50px;" v-html="news.content"></div>
			</div>
		    
		  </el-dialog>




	</div>
</template>

<script>
	import {
		inject,
		ref
	} from "vue";
	import {
		generalGet,
		generalCount,
		generalGetOne
	} from '@/api/general'

	// import ResizeMixin from'./mixin/ResizeHandler'
	export default {

		components: {

		},
		// mixins: [ResizeMixin],
		computed: {


		},
		setup(props, context) {

			// const VUE_APP_PIC_API = inject('global').$VUE_APP_PIC_API
			return {
				// VUE_APP_PIC_API: VUE_APP_PIC_API,
				dialogVisible: ref(false),
				news: ref({}),
				newsList: ref({}),
				firstNews: ref({}),
				newsCount: ref(0),
				pageIndex: ref(1),
				pageCount: ref(1),
				pageSize: ref(3),
				where: {
					state: 1
				}
			};
		},


		created() {
			var _this = this


			generalGet("news", {
				filter: {
					order: 'publishTime DESC',
					limit: 1,
					fields: {
						id: true,
						title: true,
						publishTime: true,
						imageUrl: true
					},
					where: _this.where
				}
			}).then(response => {
				if (response.length > 0) {
					_this.firstNews = response[0]
				}


			}).catch(error => {
				this.$message.error(error)
			})
			generalCount("news", {where:_this.where}).then(response => {
				console.log(response.count)
				_this.pageCount = Math.ceil(response.count / _this.pageSize)-1

				_this.getNews()
			}).catch(error => {
				this.$message.error(error)
			})

		},
		methods: {
			openPage(id){
				var _this = this
				console.log(id)
				generalGetOne("news",id, {
					
				}).then(response => {
				
					_this.news = response
				_this.dialogVisible = true
					// console.log(_this.newsList)
				}).catch(error => {
					this.$message.error(error)
				})
			},
			getNews() {
				var _this = this
				generalGet("news", {
					filter: {
						order: 'publishTime DESC',
						limit: _this.pageSize,
						skip: (_this.pageIndex - 1) * _this.pageSize + 1,
						fields: {
							id: true,
							title: true,
							publishTime: true,
							imageUrl: true
						},
						where: _this.where
					}
				}).then(response => {

					_this.newsList = response

					console.log(_this.newsList)
				}).catch(error => {
					this.$message.error(error)
				})
			},
			changeCurrentOrPage(e) {
				console.log(e)
				this.pageIndex = e
				this.getNews()
			}

		}

	}
</script>

<style scoped>
	.hang {
		/* background-color: white; */
		border-radius: 5px;
		margin-bottom: 5px;
		max-width: 90vw;
		display: block;
		width: fit-content;
		height: 80px;
		/* background: -o-linear-gradient(left, #2A9CF5, #6BDEF5); */
		background: -webkit-linear-gradient(left, #ffffff80, #2A9CF580);
		/* background-color: ; */
	}

	.blockP {
		line-height: 0;
		margin: 0;
	}

	.block {
		width: 25px;
		border-radius: 5px;
		display: inline-block;
		height: 25px;
		margin: 0;
		padding: 0;

		border: 1.25px solid white;
		line-height: 0;
	}

	.green {
		background-color: #DB007F;
	}

	.gray {
		background-color: #B8B8B8;
	}

	.blue1 {
		background-color: #2A9CF5;
	}

	.blue2 {
		background-color: #0776F9;
	}

	.alpha {
		opacity: 0;
	}
	
</style>
<style>
	.el-dialog__body img{
		max-width: 100%;
	}
</style>